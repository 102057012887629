export class ApiPath {
  public static BASE_URL = process.env.VUE_APP_BASE_URL;
  public static LOGIN_URI = '/users/sign_in';
  public static LOGOUT_URI = '/users/sign_out';
  public static USERS = '/users';
  public static USERS_CONFIRMATION = '/confirmations';
  public static CURRENT_USER = '/users/current';
  public static CURRENT_ADMIN_USER = '/users/current_admin';
  public static PROBE_URI = '/users/probe';
  public static STUDIO_INFO = '/studio/info';
  public static RESERVATIONS = '/studio/reservations';
  public static GET_RESERVATION_OPTIONS = '/studio/reservation_options';
  public static RESERVE = '/studio/reserve';
  public static ROOM_IMAGE = '/studio/images';
  public static USAGE = '/studio/usage';
  public static ROOMS = '/studio/rooms';
  public static ATTENDANCES = '/attendances'
  public static COUPONS = '/coupons';
  public static STORES = '/stores';
  public static GET_RESERVATIONS = '/get-reservations';
  public static GET_ATTENDANCES = '/get-attendances';
  public static GET_USERS = '/get-users';
  public static PAYJP_CHARGE = '/payjp/charge';
  public static PAYJP_CREDITCARD = '/payjp/credit_card';
  public static OPTIONS = '/studio/options'
  public static OPTION_CATEGORIES = '/studio/option_categories';
  public static STORE_OPTION_REFS = '/studio/store_option_refs';

}

export class PageName {
  public static TMP_REGISTER = '/tmp_register';
  public static UPDATE = '/update';
  public static LOGIN = '/login';
  public static SUCCESS_TMP_REGISTER = '/success_tmp_register';
  public static SENT_CONFIRMATION_MAIL = '/sent_confirmation_mail';
  public static MYPAGE = '/mypage';
  public static SYSTEM_ERROR = '/system_error';
  public static UPDATE_CONFIRMATION = '/update_confirmation';
  public static STUDIO_SEARCH = '/studio_search';
  public static STUDIO_DETAIL = '/studio_detail';
  public static STUDIO_CONFIRM = '/studio_confirm';
  public static STUDIO_COMPLETE = '/studio_complete';
}

export class Switch {
  public static DEBUG_SWITCH = process.env.VUE_APP_DEBUG_SW;
}

export class Reg {
  public static nameRegexp = new RegExp('^[ぁ-んァ-ン一-龥a-zA-Z0-9 .　!#%\'*+/=^_`{|}~-]{2,20}$');
  public static telRegexp  = new RegExp('^[0-9]{1,11}$');
  public static mailAddressRegexp = new RegExp('^[a-zA-Z0-9.!#$%\'*+/=^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+){1,254}$');
  // public static mailAddressRegexp = new RegExp('^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,254}$');
  public static passwordRegexp = new RegExp('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z0-9]{6,20}$');
  public static dateRegexp = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}$');
  public static othersRegexp = new RegExp('^[ぁ-んァ-ン一-龥a-zA-Z0-9 　a-zA-Z0-9_]{0,50}$');
  public static percentRegexp = new RegExp('^[0-9]{1,3}$');
}
