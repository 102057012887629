import axios, {AxiosRequestConfig} from 'axios';
import {ApiPath, PageName} from '@/common/core/common';
import Vue from "vue";

export class AdapterBase extends Vue {

  // @ts-ignore
  protected axiosRequestConfig: AxiosRequestConfig;

  /**
   * サーバサイドへのリクエストを行う。
   *
   * @param method
   * @param url
   * @param params
   */
  protected request<T>(method: string, url: string, json: boolean, params: any): Promise<T> {
    return new Promise((resolve, reject) => {
      if (json) {
        let accessToken = '';
        let client = '';
        let uid = '';
        const cookie = document.cookie;
        const cookies = cookie.split(';');
        for (let c of cookies) {
          c = c.trim();
          const cArray = c.split('=');
          if (cArray[0] === 'access-token') {
            accessToken = cArray[1];
            continue;
          }
          if (cArray[0] === 'client') {
            client = cArray[1];
            continue;
          }
          if (cArray[0] === 'uid') {
            uid = cArray[1];
          }
        }
        this.axiosRequestConfig = {
          headers: {
            Accept: 'application/json',
            'access-token': accessToken,
            client: client,
            uid: uid
          },
          withCredentials: true,
          method,
          url: ApiPath.BASE_URL + url,
          data: {},
        };
      } else {
        this.axiosRequestConfig = {
          method,
          url: ApiPath.BASE_URL + url,
        };
      }
      // 管理画面からアクセスしていることがわかるフラグを追加
      params.admin = true;
      if (method === 'GET' || method === 'DELETE') {
        this.axiosRequestConfig.params = params;
      } else {
        this.axiosRequestConfig.data = params;
        this.axiosRequestConfig.withCredentials = true;
      }
      axios(this.axiosRequestConfig).then((res) => {
        if (url === ApiPath.LOGIN_URI) {
          // 認証情報をクッキーへ保存
          const maxDay = 1
          const maxAge = maxDay * 24 * 60 * 60; // Cookieの有効期限は秒で指定する[1日設定]
          document.cookie = 'access-token=' + res.headers['access-token'] + ';max-age=' + maxAge.toString() + ';';
          document.cookie = 'client=' + res.headers['client'] + ';max-age=' + maxAge.toString() + ';';
          document.cookie = 'uid=' + res.headers['uid'] + ';max-age=' + maxAge.toString() + ';';

          const  get_formated_time = (
            _fmt = 'YYYY/MM/DD hh:mm:ss.iii',
            _dt  = new Date(),
          ) => [
                [ 'YYYY', _dt.getFullYear()  ],
                [ 'MM',   _dt.getMonth() + 1 ],
                [ 'DD',   _dt.getDate()      ],
                [ 'hh',   _dt.getHours()     ],
                [ 'mm',   _dt.getMinutes()   ],
                [ 'ss',   _dt.getSeconds()   ],
                [ 'iii',  _dt.getMilliseconds() ],
          ].reduce(
                (s,a) => s.replace( String(a[0]), `${a[1]}`.padStart(String(a[0]).length,'0') ),
                _fmt
          )
          document.cookie = 'login=' + get_formated_time( 'YYYY/MM/DD hh:mm:ss', new Date() ) + ';max-age=' + maxAge.toString() + ';';
        }
        if (url === ApiPath.LOGOUT_URI) {
          // 認証情報をクッキーから削除
          const cookie = document.cookie;
          const cookies = cookie.split(';');
          for (const c of cookies) {
            const cArray = c.split('=');
            document.cookie = cArray[0] + '=;max-age=0'
          }
          // ローカルストレージの削除
          localStorage.clear();
        }
        resolve(res.data);
      }).catch((err) => {
        if(err.response.status === 401) {
          if(url === ApiPath.LOGIN_URI) {
            err.response.data.message = 'ログインに失敗しました。メールアドレスとパスワードをご確認の上、ログインし直してください。';
          } else {
            err.response.data.message = 'ログインセッションが無効になっています。画面を更新して、再度ログインしてください。';
          }
        }
        reject(err.response);
      });
    });

  }
}
