export default {
  reservation: {
    howToPay: {
      0: '店頭キャッシュレス決済',
      1: 'オンライン決済',
    },
    cancelType: {
      0: '-',
      1: '当日/前日',
      2: '2〜6日前',
      3: '7日以上前',
    },
    reserveStatus: {
      0: '仮予約',
      1: '予約確定',
    }
  },
  user: {
    status: {
      0: '仮登録（メール未認証）',
      1: '仮登録（メール認証済）',
      2: '本登録',
      99: '退会済み',
    },
    grade: {
      0: '無料会員',
      1: 'VIP PASS',
      2: 'VIP DAY PASS',
      3: 'VIP MIDNIGHT PASS',
      4: 'A & C',
    },
    gender: {
      0: '男性',
      1: '女性',
      2: '無回答'
    },
    genre: [
      'Animation',
      'Ballet',
      'Break',
      'Contemporary',
      'Diabolo',
      'FreeStyle',
      'FreeStyleBasketball',
      'FreeStyleFootball',
      'FreeStyleHustle',
      'Girls',
      'HipHop',
      'House',
      'Jazz',
      'JazzFunk',
      'JazzHipHop',
      'KPop',
      'Latin',
      'Lock',
      'Pantomime',
      'Pop',
      'Punking',
      'RB',
      'Salsa',
      'Soul',
      'Tap',
      'Tut',
      'Vogue',
      'Yoga',
      'Waack',
      'Others',
    ]
  }
};
