<template>
  <v-app v-if="$route.path==='/login'">
    <router-view />
  </v-app>
  <v-app v-else>
    <!-- ナビゲーション -->
    <v-navigation-drawer app clipped permanent color="grey lighten-4" class="navigation">
      <v-container>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              ナビゲーション
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list dense nav>
          <v-list-item v-for="nav_list in nav_lists" :key="nav_list.name" :to="nav_list.link">
            <v-list-item-content>
              <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-container>
    </v-navigation-drawer>

    <!-- ヘッダー -->
    <v-app-bar color="grey lighten-4" app clipped-left elevation=1>
      <v-toolbar-title>管理画面</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text">last login : {{login_date}}</div>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text>ダッシュボード</v-btn>
        <v-btn text>ホーム</v-btn>
        <v-btn text><v-icon>mdi-power-standby</v-icon>mailAddress</v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <!-- コンテンツ -->
    <v-main class="ma-3 contents">
      <router-view />
    </v-main>

  </v-app>
</template>
<style>
.navigation {
  height: 100vh;
  top: 64px !important;
  max-height: calc(100% - 64px);
  transform: translateX(0%);
  width: 256px;
}
.contents {
  padding: 64px 0 0 256px !important;
}

</style>
<script>

export default {
  data(){
    return{
      nav_lists:[
        {
          name:'Attendance',link:'/attendance'
        },
        {
          name:'Coupon',link:'/coupon'
        },
        {
          name:'Reservation',link:'/reservation'
        },
        {
          name:'Room',link:'/room'
        },
        {
          name:'User',link:'/user'
        },
        {
          name:'RoomImage', link: '/room_image'
        },
        {
          name:'Payjp', link: '/payjp'
        },
        {
          name:'Option', link: '/option'
        }
      ],
      login_date: "Login again .."
    }
  },
  methods: {
    setMeta(to) {
      if (to.meta.title) {
        document.title = to.meta.title;
      }
      if(to.meta.desc) {
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.desc);
      }
    },
    setLoginInfo() {
        var arr = new Array();
        if(document.cookie != ''){
            var tmp = document.cookie.split('; ');
            for(var i=0;i<tmp.length;i++){
                var data = tmp[i].split('=');
                arr[data[0]] = decodeURIComponent(data[1]);
            }
        }
        return arr['login'];
    }
  },
  mounted : function () {
        this.login_date = this.setLoginInfo();
  },
  beforeUpdate : function () {
        this.login_date = this.setLoginInfo();
  },
  watch: {
    $route(to) {
      this.setMeta(to);
    }
  },
  name: 'App',
};

</script>
