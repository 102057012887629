import {Component, Prop, Vue} from 'vue-property-decorator';
import {ClientAdapter} from '@/common/core/clientAdapter';
import config from 'config';

@Component
export class VueEx extends Vue {

  public static memberId: string = '';
  public static userName: string = '';
  public static status: number = 0;
  public config?: any;

  public clientAdapter: ClientAdapter = new ClientAdapter();

  created() {
    this.config = config;
  }

}
