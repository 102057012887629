import {AdapterBase} from '@/common/core/adapterBase';
import {ApiPath} from '@/common/core/common';

/**
 * API 呼び出しクラス
 */
export class ClientAdapter extends AdapterBase {

    /**
     * 仮登録を行う
     * @param mailAddress
     * @param password
     * @param name
     * @param tel
     * @param birthday
     * @param gender
     * @param genre
     * @param adminFlg
     * @param grade
     */
    public registerMemberByAdmin<T>(mailAddress: string, password: string, name: string, tel: string, birthday: string, gender: string, genre: string, adminFlg: boolean, grade: string, memo: string): Promise<T> {
        return this.request('POST', ApiPath.USERS, true, {
            email: mailAddress,
            password: password,
            name: name,
            tel: tel,
            birthday: birthday,
            gender: gender,
            genre: genre,
            admin_flg: adminFlg,
            grade: grade,
            isByAdmin: true,
            memo: memo,
        });
    }

    /**
     * 仮登録を行う
     * @param id
     * @param mailAddress
     * @param name
     * @param tel
     * @param birthday
     * @param gender
     * @param genre
     * @param adminFlg
     * @param grade
     * @param status
     * @param memo
     */
    public updateMemberByAdmin<T>(id: number, mailAddress: string, name: string, tel: string, birthday: string, gender: string, genre: string, adminFlg: boolean, grade: string, status: number, memo: string): Promise<T> {
        return this.request('PUT', ApiPath.USERS, true, {
            id: id,
            email: mailAddress,
            name: name,
            tel: tel,
            birthday: birthday,
            gender: gender,
            genre: genre,
            admin_flg: adminFlg,
            grade: grade,
            status: status,
            memo: memo,
        });
    }

    /**
     * ユーザを退会させる
     * @param id
     */
    public withdrawUserByAdmin<T>(id: number) {
        return this.request('DELETE', ApiPath.USERS, true, {id: id});
    }

    /**
     * オプションを削除する
     * @param id
     */
    public withdrawOptionByAdmin<T>(id: number) {
        return this.request('DELETE', ApiPath.OPTIONS, true, {id: id});
    }

    /**
     * オプション参照情報を削除する
     * @param id
     */
    public withdrawStoreOptioRefByAdmin<T>(id: number) {
        return this.request('DELETE', ApiPath.STORE_OPTION_REFS, true, {option_id: id});
    }

    /**
     * HEADER : CSRFトークンの取得を行う。
     */
    public getProbe<T>(): Promise<{}> {
        return this.request('GET', ApiPath.PROBE_URI, true, {});
    }

    /**
     * ログインを行う。
     *
     * @param mailAddress
     * @param password
     */
    public loginSystem<T>(mailAddress: string, password: string): Promise<{ status: string }> {
        return this.request('POST', ApiPath.LOGIN_URI, true,
            {email: mailAddress, password: password});
    }

    /**
     * ログアウトを行う。
     *
     */
    public logoutSystem<T>(): Promise<{ result: {} }> {
        return this.request('DELETE', ApiPath.LOGOUT_URI, true, {});
    }

    /**
     * 本登録を行う。
     *
     * @param memberId
     * @param birthday
     * @param gender
     * @param genre
     */
    public register<T>(memberId: string, birthday: string, gender: string, genre: string): Promise<T> {
        return this.request('PUT', ApiPath.USERS, true,
            {memberId: memberId, birthday: birthday, gender: gender, genre: genre});
    }

    /**
     * 仮登録処理を完了する。
     *
     * @param query
     */
    public completeTmpRegister<T>(query: string[]): Promise<T> {
        return this.request('GET', ApiPath.USERS_CONFIRMATION, true, {query: query});

    }

    /**
     * ログイン中のユーザ情報を取得する。
     */
    public getCurrentUserInfo<T>(): Promise<{ memberId: string, userName: string, status: number }> {
        return this.request('GET', ApiPath.CURRENT_USER, true, {});
    }

    /**
     * ログイン中のユーザ情報を取得する。（管理画面用）
     */
    public getCurrentAdminUserInfo<T>(): Promise<{ memberId: string, userName: string, status: number }> {
        return this.request('GET', ApiPath.CURRENT_ADMIN_USER, true, {});
    }

    /**
     * スタジオ情報を取得する。
     *
     * @param store_id
     */
    public getStudioInfo<T>(store_id: number): Promise<{ id: number, name: string, area: number, capacity: number, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, studioImageUrl: string, material: string, vertical: number, width: number, created_at: string, updated_at: string }[]> {
        return this.request('GET', ApiPath.STUDIO_INFO, false, {store_id: store_id});
    }

    /**
     * すべてのスタジオ情報を取得する。
     *
     */
    public getAllStudioInfo<T>(): Promise<{ id: number, name: string, area: number, capacity: number, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, store_id: number, studioImageUrl: string, material: string, vertical: number, width: number, created_at: string, updated_at: string, onsite_pay_available: boolean }[]> {
        return this.request('GET', ApiPath.STUDIO_INFO, false, {});
    }

    /**
     * 予約情報を取得する
     */
    public getReservations<T>(options: any, searchCondition: any): Promise<{ total: number, reservations: { id: number, createTime: string, reserveStartTime: string, reserveEndTime: string, midFlg: boolean, earlyFlg: boolean, howToPay: number, billing: number, studioBilling: number, optionBilling: number, isCommercialUse: boolean, paymentFlg: boolean, cancelDate: string, cancelType: number, couponCode: string, userName: string, userTel: string, userEmail: string, user_id: number, roomName: string, reserveStatus: number, created_at: string, updated_at: string, highLevelUsage: string, detailUsage: string, numberOfUsers: number, memo: string, memoForAdmin: string, pay_jp_billing_id: string, is_payment_skipped: boolean }[] }> {
        return this.request('POST', ApiPath.GET_RESERVATIONS, true, {
            options: options,
            searchCondition: searchCondition
        });
    }

    /**
     * スタジオの予約をする
     * @param roomId
     * @param startTime
     * @param endTime
     * @param midFlg
     * @param earlyFlg
     * @param howToPay
     * @param reserveStatus
     * @param billing
     * @param couponCode
     * @param highLevelUsage
     * @param detailUsage
     * @param numberOfUsers
     */
    public reserve<T>(roomId: number, startTime: string, endTime: string, midFlg: boolean, earlyFlg: boolean, howToPay: number, billing: number, couponCode: string, highLevelUsage: string, detailUsage: string, reserveStatus: number, numberOfUsers: number): Promise<T> {
        return this.request('POST', ApiPath.RESERVE, true, {
            room_id: roomId,
            reserveStartTime: startTime,
            reserveEndTime: endTime,
            midFlg: midFlg,
            earlyFlg: earlyFlg,
            HowToPay: howToPay,
            billing: billing,
            couponCode: couponCode,
            highLevelUsage: highLevelUsage,
            detailUsage: detailUsage,
            reserveStatus: reserveStatus,
            numberOfUsers: numberOfUsers
        });
    }

    /**
     * 管理画面からスタジオ予約を行う
     * @param roomId
     * @param startTime
     * @param endTime
     * @param midFlg
     * @param earlyFlg
     * @param howToPay
     * @param billing
     * @param studioBilling
     * @param couponCode
     * @param highLevelUsage
     * @param detailUsage
     * @param numberOfUsers
     * @param userId
     * @param reserveStatus
     * @param is_payment_skipped
     * @param memo
     * @param memoForAdmin
     * @param optionReserved
     * @param actualPrice
     * @param optionTotalPrice
     * @param isCommercialUse
     * @param options
     */
    public reserveByAdmin<T>(roomId: number, startTime: string, endTime: string, midFlg: boolean, earlyFlg: boolean, howToPay: number, billing: number, studioBilling: number, couponCode: string, highLevelUsage: string, detailUsage: string, numberOfUsers: number, userId: number, reserveStatus: string, is_payment_skipped: boolean, memo: string, memoForAdmin: string, optionReserved: boolean, actualPrice: number, optionTotalPrice: number, isCommercialUse: boolean, options: { id: number, amount: number, billing: number }[]): Promise<{ id: number }> {
        return this.request('POST', ApiPath.RESERVE, true, {
                room_id: roomId,
                reserveStartTime: startTime,
                reserveEndTime: endTime,
                midFlg: midFlg,
                earlyFlg: earlyFlg,
                howToPay: howToPay,
                billing: billing,
                couponCode: couponCode,
                highLevelUsage: highLevelUsage,
                detailUsage: detailUsage,
                numberOfUsers: numberOfUsers,
                user_id: userId,
                reserveStatus: reserveStatus,
                is_payment_skipped: is_payment_skipped,
                memo: memo,
                memoForAdmin: memoForAdmin,
                has_option: optionReserved,
                studioBilling: studioBilling,
                optionBilling: optionTotalPrice,
                isCommercialUse: isCommercialUse,
                options: options
            }
        );
    }

    /**
     * 管理画面から予約変更をする
     * @param id
     * @param roomId
     * @param startTime
     * @param endTime
     * @param midFlg
     * @param earlyFlg
     * @param howToPay
     * @param billing
     * @param studioBilling
     * @param optionsBilling
     * @param couponCode
     * @param highLevelUsage
     * @param detailUsage
     * @param numberOfUsers
     * @param userId
     * @param reserveStatus
     * @param is_payment_skipped
     * @param memo
     * @param paymentFlg
     * @param memoForAdmin
     * @param optionReserved
     * @param actualPrice
     * @param optionTotalPrice
     * @param isCommercialUse
     * @param options
     */
    public updateReservationByAdmin<T>(id: number, roomId: number, startTime: string, endTime: string, midFlg: boolean, earlyFlg: boolean, howToPay: number, billing: number, studioBilling: number, optionsBilling: number, couponCode: string, highLevelUsage: string, detailUsage: string, numberOfUsers: number, userId: number, reserveStatus: number, is_payment_skipped: boolean, memo: string, paymentFlg: boolean, memoForAdmin: string, optionReserved: boolean, actualPrice: number, optionTotalPrice: number, isCommercialUse: boolean, options: { id: number, amount: number, billing: number }[]): Promise<T> {
        return this.request('PUT', ApiPath.RESERVE, true, {
            id: id,
            room_id: roomId,
            reserveStartTime: startTime,
            reserveEndTime: endTime,
            midFlg: midFlg,
            earlyFlg: earlyFlg,
            howToPay: howToPay,
            billing: billing,
            optionsBilling: optionsBilling,
            couponCode: couponCode,
            highLevelUsage: highLevelUsage,
            detailUsage: detailUsage,
            numberOfUsers: numberOfUsers,
            user_id: userId,
            reserveStatus: reserveStatus,
            is_payment_skipped: is_payment_skipped,
            memo: memo,
            paymentFlg: paymentFlg,
            memoForAdmin: memoForAdmin,
            has_option: optionReserved,
            studioBilling: studioBilling,
            optionBilling: optionTotalPrice,
            isCommercialUse: isCommercialUse,
            options: options
        });
    }

    /**
     * 予約をキャンセルする
     * @param id
     * @param cancelType
     */
    public cancelReservation<T>(id: number, cancelType: number, cancelPrice: number, cancelReason: string) {
        return this.request('DELETE', ApiPath.RESERVE, true, {
            id: id,
            cancelType: cancelType,
            cancelPrice: cancelPrice,
            cancelReason: cancelReason
        });
    }

    /**
     * スタジオ用途を取得する
     */
    public getUsages<T>(): Promise<{ id: number, usage: string, parentFlg: boolean, parentUsageId: number }[]> {
        return this.request('GET', ApiPath.USAGE, false, {});
    }

    /**
     * スタジオ画像を取得する
     */
    public getAllRoomImages<T>(): Promise<{ id: number, rooms_id: number, imageUrl: string, show_priority: number, created_at: string, updated_at: string }[]> {
        return this.request('GET', ApiPath.ROOM_IMAGE, false, {});
    }

    /**
     * スタジオ画像を保存する
     * @param roomId
     * @param imageUrl
     * @param showPriority
     */
    public createRoomImage<T>(roomId: number, imageUrl: string, showPriority: number): Promise<T> {
        return this.request('POST', ApiPath.ROOM_IMAGE, true, {
            studio_image: {
                rooms_id: roomId,
                imageUrl: imageUrl,
                show_priority: showPriority
            }
        });
    }

    /**
     * 全ユーザ情報を取得する
     */
    public getUsers<T>(options: any, searchCondition: any): Promise<{ total: number, users: { id: number, name: string, member_id: number, tel: string, status: number, grade: number, admin_flg: boolean, email: string, birthday: string, gender: number, genre: string, memo: string, /** 本当は取りたいけど取れない last_sign_in_at: string, failed_attempts: number, */line_id: string, payjp_customer_id: string, created_at: string, updated_at: string }[] }> {
        return this.request('POST', ApiPath.GET_USERS, true, {options: options, searchCondition: searchCondition});
    }

    /**
     * 指定した予約情報を取得する
     * @param id
     */
    public getReservation<T>(id: number): Promise<{ id: number, createTime: string, reserveStartTime: string, reserveEndTime: string, midFlg: boolean, earlyFlg: boolean, howToPay: number, billing: number, studioBilling: number, optionBilling: number, isCommercialUse: boolean, paymentFlg: boolean, cancelDate: string, cancelType: number, couponCode: string, userName: string, memberId: number, roomName: string, reserveStatus: number, created_at: string, updated_at: string, highLevelUsage: string, detailUsage: string, numberOfUsers: number, user_id: string, room_id: number, memo: string, memoForAdmin: string, cancelReason: string, pay_jp_billing_id: string, is_payment_skipped: boolean }> {
        return this.request('GET', ApiPath.RESERVATIONS, false, {id: id});
    }

    /**
     * 指定した予約のオプション情報を取得する
     * @param reservation_id
     */
    public getReservationOption<T>(reservation_id: number): Promise<{ reservation_options: { id: number, reservation_id: number, option_id: number, amount: number, created_at: string, updated_at: string, optionBilling: number }[] }> {
        return this.request('GET', ApiPath.GET_RESERVATION_OPTIONS, false, {id: reservation_id});
    }

    /**
     * 部屋を登録する
     * @param name
     * @param area
     * @param capacity
     * @param dayNormalPrice
     * @param dayCreditPrice
     * @param dayEarlyPrice
     * @param MidNormalPrice
     * @param MidCreditPrice
     * @param MidEarlyPrice
     * @param storeId
     * @param material
     * @param vertical
     * @param width
     */
    public createRoom<T>(name: string, area: number, capacity: number, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, storeId: number, material: string, vertical: number, width: number, blob: Blob) {
        return this.request('POST', ApiPath.ROOMS, true, {
            name: name,
            area: area,
            capacity: capacity,
            dayNormalPrice: dayCreditPrice,
            dayCreditPrice: dayCreditPrice,
            dayEarlyPrice: dayEarlyPrice,
            MidNormalPrice: MidNormalPrice,
            MidCreditPrice: MidCreditPrice,
            MidEarlyPrice: MidEarlyPrice,
            store_id: storeId,
            material: material,
            vertical: vertical,
            width: width,
            studioImage: blob
        });
    }

    /**
     * Roomを更新する
     * @param id
     * @param name
     * @param area
     * @param capacity
     * @param dayNormalPrice
     * @param dayCreditPrice
     * @param dayEarlyPrice
     * @param MidNormalPrice
     * @param MidCreditPrice
     * @param MidEarlyPrice
     * @param storeId
     * @param material
     * @param vertical
     * @param width
     * @param openingHour
     * @param closingHour
     * @param is_park
     */
    public updateRoom<T>(id: number, name: string, area: number, capacity: number, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, storeId: number, material: string, vertical: number, width: number, openingHour: string, closingHour: string, is_park: boolean) {
        return this.request('PUT', ApiPath.ROOMS, true, {
            id: id,
            name: name,
            area: area,
            capacity: capacity,
            dayNormalPrice: dayNormalPrice,
            dayCreditPrice: dayCreditPrice,
            dayEarlyPrice: dayEarlyPrice,
            MidNormalPrice: MidNormalPrice,
            MidCreditPrice: MidCreditPrice,
            MidEarlyPrice: MidEarlyPrice,
            store_id: storeId,
            material: material,
            vertical: vertical,
            width: width,
            openingHour: openingHour,
            closingHour: closingHour,
            is_park: is_park,
        });
    }

    /**
     * スタジオ情報を取得する。
     *
     * @param store_id
     */
    public getStudioInfoById<T>(id: number): Promise<{ id: number, name: string, area: number, capacity: number, dayNormalPrice: number, dayCreditPrice: number, dayEarlyPrice: number, MidNormalPrice: number, MidCreditPrice: number, MidEarlyPrice: number, created_at: string, updated_at: string, store_id: number, material: string, vertical: number, width: number, openingHour: string, closingHour: string, is_park: boolean }> {
        return this.request('GET', ApiPath.STUDIO_INFO, false, {id: id});
    }

    /**
     * Attendanceを登録する
     * @param intime
     * @param outtime
     * @param userId
     */
    public createAttendance<T>(intime: string, outtime: string, userId: number, storeId: number) {
        return this.request('POST', ApiPath.ATTENDANCES, true, {
            intime: intime,
            outtime: outtime,
            user_id: userId,
            store_id: storeId
        });
    }

    /**
     * Attendanceを更新する
     * @param id
     * @param intime
     * @param outtime
     * @param userId
     */
    public updateAttendance<T>(id: number, intime: string, outtime: string, userId: number) {
        return this.request('PUT', ApiPath.ATTENDANCES + '/' + id, true, {
            intime: intime,
            outtime: outtime,
            user_id: userId
        });
    }

    /**
     * Attendanceを削除する
     * @param id
     */
    public deleteAttendance<T>(id: number) {
        return this.request('DELETE', ApiPath.ATTENDANCES + '/' + id, true, {});
    }

    /**
     * Attendanceを取得する
     */
    public getAttendances<T>(options: any, searchCondition: any): Promise<{ total: number, attendances: { id: number, intime: string, outtime: string, userId: number, userName: string, userTel: string, userEmail: string, payjpCustomerId: string, storeName: string, billing_amount: number, is_canceled: boolean, remote_lock_key_pin: number, pay_jp_billing_id: string, created_at: string, updated_at: string }[] }> {
        return this.request('POST', ApiPath.GET_ATTENDANCES, true, {
            options: options,
            searchCondition: searchCondition
        });
    }

    /**
     * idで指定したAttendanceを取得する
     */
    public getAttendanceById<T>(id: number): Promise<{ id: number, intime: string, outtime: string, user_id: number, created_at: string, updated_at: string }> {
        return this.request('GET', ApiPath.ATTENDANCES, true, {id: id});
    }

    /**
     * メールアドレスで指定したユーザを取得する
     * @param email
     */
    public getUserByEmail<T>(email: string): Promise<{ id: number, name: string, member_id: number, tel: string, status: number, grade: number, admin_flg: boolean, email: string, birthday: string, gender: number, genre: string, /** 本当は取りたいけど取れない last_sign_in_at: string, failed_attempts: number, */  line_id: string, payjp_customer_id: string, created_at: string, updated_at: string, memo: string }[]> {
        return this.request('GET', ApiPath.USERS, true, {email: email});
    }

    /**
     * クーポン情報を取得する
     */
    public getAllCoupons<T>(): Promise<{ id: number, code: string, name: string, validStartDate: string, validEndDate: string, discountRate: number, activeFlg: boolean, created_at: string, updated_at: string }[]> {
        return this.request('GET', ApiPath.COUPONS, true, {});
    }

    /**
     * クーポン情報を取得する
     */
    public getCouponById<T>(id: number): Promise<{ id: number, code: string, name: string, validStartDate: string, validEndDate: string, discountRate: number, activeFlg: boolean, created_at: string, updated_at: string }> {
        return this.request('GET', ApiPath.COUPONS, true, {id: id});
    }

    /**
     * クーポンを作成する
     * @param code
     * @param name
     * @param validStartDate
     * @param validEndDate
     * @param discountRate
     * @param activeFlg
     */
    public createCouponByAdmin<T>(code: string, name: string, validStartDate: string, validEndDate: string, discountRate: number, activeFlg: boolean) {
        return this.request('POST', ApiPath.COUPONS, true, {
            code: code,
            name: name,
            validStartDate: validStartDate,
            validEndDate: validEndDate,
            discountRate: discountRate,
            activeFlg: activeFlg
        });
    }

    /**
     * クーポンを更新する
     * @param id
     * @param code
     * @param name
     * @param validStartDate
     * @param validEndDate
     * @param discountRate
     * @param activeFlg
     */
    public updateCouponByAdmin<T>(id: number, code: string, name: string, validStartDate: string, validEndDate: string, discountRate: number, activeFlg: boolean) {
        return this.request('PUT', ApiPath.COUPONS, true, {
            id: id,
            code: code,
            name: name,
            validStartDate: validStartDate,
            validEndDate: validEndDate,
            discountRate: discountRate,
            activeFlg: activeFlg
        });
    }

    /**
     * 店舗情報を取得する
     */
    public getStoreInfo<T>(): Promise<{ id: number, store_name: string, address: string, closed_flg: boolean, created_at: string, updated_at: string, onsite_pay_available: boolean, equipment_rentalable: boolean }[]> {
        return this.request('GET', ApiPath.STORES, false, {});
    }

    public getUser<T>(userId: string): Promise<T> {
        return this.request('GET', ApiPath.USERS, true, {user_id: userId});
    }

    /**
     * 指定した期間の請求一覧を取得する
     * @param chargeDateFrom
     * @param chargeDateTo
     */
    public getChargeList<T>(chargeDateFrom: number, chargeDateTo: number): Promise<{ id: string, customer: string, captured: boolean, captured_at: number, description: string, refunded: boolean, amount_refunded: number }[]> {
        return this.request('GET', ApiPath.PAYJP_CHARGE, false, {from: chargeDateFrom, to: chargeDateTo});
    }

    /**
     * 指定した顧客のクレカ情報を取得する
     * @param customerId
     */
    public getCreditCard<T>(userId: string): Promise<{ id: string, exp_year: string, exp_month: string, last4: string, brand: string }[]> {
        return this.request('GET', ApiPath.PAYJP_CREDITCARD, false, {userId: userId});
    }

    /**
     * オプションを登録する
     * @param name
     * @param price
     * @param unit
     * @param description
     * @param max_rental_amount
     * @param image
     * @param memo
     * @param category_id
     */
    public createOption<T>(name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string, category_id: number): Promise<{ id: number }> {
        return this.request('POST', ApiPath.OPTIONS, true, {
            name: name,
            price: price,
            unit: unit,
            description: description,
            max_rental_amount: max_rental_amount,
            image: image,
            memo: memo,
            category_id: category_id
        });
    }

    /**
     * Optionを更新する
     * @param id
     * @param option_id
     * @param name
     * @param price
     * @param unit
     * @param description
     * @param max_rental_amount
     * @param image
     * @param memo
     * @param category_id
     */
    public updateOption<T>(id: number, option_id: number, name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string, category_id: number) {
        return this.request('PUT', ApiPath.OPTIONS, true, {
            id: id,
            optionId: option_id,
            name: name,
            price: price,
            unit: unit,
            description: description,
            max_rental_amount: max_rental_amount,
            image: image,
            memo: memo,
            category_id: category_id
        });
    }

    /**
     * オプション参照情報を登録する
     * @param storeId
     * @param option_id
     */
    public createStoreOptionRef<T>(storeId: number, option_id: number) {
      return this.request('POST', ApiPath.STORE_OPTION_REFS, true, {
        store_id: storeId,
        option_id: option_id
      });
    }
  
    /**
   * オプション参照情報を更新する
   * @param storeId
   * @param option_id
   */
    public updateStoreOptionRef<T>(storeId: number, option_id: number) {
      return this.request('PUT', ApiPath.STORE_OPTION_REFS, true, {
        store_id: storeId,
        option_id: option_id
      });
    }
  
   /**
   * 全ての店舗-オプション参照情報を取得する
   */
   public getAllStoreOptionRefs<T>(): Promise<{id: number, store_id: number, option_id: number}[]> {
    return this.request('GET', ApiPath.STORE_OPTION_REFS, false, {});
  }
   /**
   * 店舗-オプション参照情報を取得する
   */
   public getStoreOptionRefs<T>(storeId: number): Promise<{id: number, store_id: number, option_id: number}[]> {
    return this.request('GET', ApiPath.STORE_OPTION_REFS, false, {store_id: storeId});
  }
   /**
   * オプションから店舗-オプション参照情報を取得する
   */
   public getStoreOptionRefsByOptionId<T>(optionId: number): Promise<{id: number, store_id: number, option_id: number}[]> {
    return this.request('GET', ApiPath.STORE_OPTION_REFS, false, {option_id: optionId});
  }

   /**
   * オプション参照情報を取得する
    @param option_id
   */
   public getOptionById<T>(optionId: number): Promise<{id: number, option_id: number, name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string, created_at: string, updated_at: string, category_id: number}> {
    return this.request('GET', ApiPath.OPTIONS, false, {option_id: optionId});
  }
    /**
   * 全てのオプションの情報を取得する
   */
    public getAllOptions<T>(): Promise<{id: number, option_id: number, name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string, created_at: string, updated_at: string, category_id: number}[]> {
      return this.request('GET', ApiPath.OPTIONS, false, {});
  }
    /**
   * オプション参照情報を取得する
   * （管理用画面）
   */
    public getOptions<T>(optionId: number): Promise<{id: number, option_id: number, name: string, price: number, unit: number, description: string, max_rental_amount: number, image: string, memo: string, category_id: number}> {
      return this.request('GET', ApiPath.OPTIONS, false, {option_id: optionId});
    }
    /**
   * オプションのカテゴリー情報を取得する
   */
    public getOptionCategories<T>(): Promise<{id: number, category_id: number, name: string}[]> {
      return this.request('GET', ApiPath.OPTION_CATEGORIES, false, {});
    }
}
