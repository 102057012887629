import Vue from 'vue';
import VueRouter from 'vue-router';
import {ClientAdapter} from '@/common/core/clientAdapter';
import {VueEx} from '@/common/core/VueEx';
import {PageName} from '@/common/core/common';

Vue.use(VueRouter);

const routes = [

  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../App.vue'),
    meta: {
      requiresAuth: true,
      title: 'Home - 管理画面【inthehouse】'
    },
  },
  {
    path: '/attendance',
    name: 'Attendance',
    component: () => import(/* webpackChunkName: "about" */ '../views/Attendance.vue'),
    meta: {
      requiresAuth: true,
      title: 'Attendance - 管理画面【inthehouse】'
    },
  },
  {
    path: '/individualAttendance',
    name: 'IndividualAttendance',
    component: () => import(/* webpackChunkName: "about" */ '../views/attendance/IndividualAttendance.vue'),
    meta: {
      requiresAuth: true,
      title: 'Attendance - 管理画面【inthehouse】'
    },
  },
  {
    path: '/coupon',
    name: 'Coupon',
    component: () => import(/* webpackChunkName: "about" */ '../views/Coupon.vue'),
    meta: {
      requiresAuth: true,
      title: 'Coupon - 管理画面【inthehouse】'
    },
  },
  {
    path: '/individualCoupons',
    name: 'IndividualCoupons',
    component: () => import(/* webpackChunkName: "about" */ '../views/coupon/IndividualCoupon.vue'),
    meta: {
      requiresAuth: true,
      title: 'Coupons - 管理画面【inthehouse】'
    },
  },
  {
    path: '/reservation',
    name: 'Reservation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reservation.vue'),
    meta: {
      requiresAuth: true,
      title: 'Reservation - 管理画面【inthehouse】'
    },
  },
  {
    path: '/individualReservation',
    name: 'IndividualReservation',
    component: () => import(/* webpackChunkName: "about" */ '../views/reservation/IndividualReservation.vue'),
    meta: {
      requiresAuth: true,
      title: 'Reservation - 管理画面【inthehouse】'
    },
  },
  {
    path: '/room',
    name: 'Room',
    component: () => import(/* webpackChunkName: "about" */ '../views/Room.vue'),
    meta: {
      requiresAuth: true,
      title: 'Room - 管理画面【inthehouse】'
    },
  },
  {
    path: '/individualRoom',
    name: 'IndividualRoom',
    component: () => import(/* webpackChunkName: "about" */ '../views/room/IndividualRoom.vue'),
    meta: {
      requiresAuth: true,
      title: 'Room - 管理画面【inthehouse】'
    },
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "about" */ '../views/User.vue'),
    meta: {
      requiresAuth: true,
      title: 'User - 管理画面【inthehouse】'
    },
  },
  {
    path: '/individualUser',
    name: 'IndividualUser',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/IndividualUser.vue'),
    meta: {
      requiresAuth: true,
      title: 'User - 管理画面【inthehouse】'
    },
  },
  {
    path: '/room_image',
    name: 'RoomImage',
    component: () => import(/* webpackChunkName: "about" */ '../views/RoomImage.vue'),
    meta: {
      requiresAuth: true,
      title: 'RoomImage - 管理画面【inthehouse】'
    },
  },
  {
    path: '/payjp',
    name: 'Payjp',
    component: () => import(/* webpackChunkName: "about" */ '../views/Payjp.vue'),
    meta: {
      requiresAuth: true,
      title: 'Payjp - 管理画面【inthehouse】'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      title: 'Login - 管理画面【inthehouse】'
    },
  },
  {
    path: '/option',
    name: 'Option',
    component: () => import(/* webpackChunkName: "about" */ '../views/Option.vue'),
    meta: {
      requiresAuth: true,
      title: 'Option - 管理画面【inthehouse】'
    },
  },
  {
    path: '/individualOption',
    name: 'IndividualOption',
    component: () => import(/* webpackChunkName: "about" */ '../views/option/IndividualOption.vue'),
    meta: {
      requiresAuth: true,
      title: 'Option - 管理画面【inthehouse】'
    },
  }
];

const clientAdapter: ClientAdapter = new ClientAdapter();

const routing = function(to: any, from: any, next: any) {
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    clientAdapter.getCurrentAdminUserInfo().then((res: { memberId: string, userName: string, status: number }) => {
      VueEx.memberId = res.memberId;
      VueEx.userName = res.userName;
      VueEx.status = res.status;
      next();
    }).catch(() => {
      next({path: PageName.LOGIN, query: {redirect: to.fullPath, from: from.path}});
    });
  } else if(to.matched.some((record: any) => record.path === '/login')) { // ログイン画面のみ
    next();
  } else {
    next({path: PageName.LOGIN});
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// >>>>>>>>> if checking local network delete this block
router.beforeEach((to, from, next) => {
  routing(to, from, next);
});
// <<<<<<<<< if checking local network delete this block


export default router;
